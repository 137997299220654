import Big from 'big.js';
import {
  Connection,
  ConnectionType,
} from '../../../services/api/models/connection';
import {
  Glass,
  GlassDimensions,
  GlassPropertiesSearch,
} from '../../../services/api/models/glass';
import {
  BaseType,
  ExtendedAnchoringOptions,
  ProductModel,
  ProductOrientation,
  ProductSearch,
  SymetryOptions,
} from '../../../services/api/models/product';
import {
  GetGlassProductsFunc,
  HasGlassInDirectionFunc,
} from '../hooks/useDropValidators';
import { GlassGlassType } from '../hooks/useGlassGlassProduct';
import { DropTarget, GlassTarget } from '../store/drag';
import { Bounds, Dimensions, Shape as ShapeType } from '../types';
import { fixValue } from './fix';
import {
  GlassNeighbors,
  Neighbor,
  NeighborGlass,
  NeighborType,
} from './neighbors';
import { DiagonalDirection } from './shadows';
import { Axis, getCornerPoints, scaleShape } from './shapes';
import { BigVector, normalizeVector } from './geometry/vectors';
import { Shape } from '../../space';
import {
  getCoordinatesForRect,
  mapCoordinatesToEdges,
  pointsDistance,
  scalePosition,
  sectionAngle,
  toPoint,
  toVector,
} from '../../../utils/shape';
import { Vector2 } from './vector';
import some from 'lodash/some';
import {
  findLine,
  findParallelLine,
  findPointOnLineInDistance,
} from './geometry/lines';
import { DistanceTypes } from '../store/products';
import { Vector2d } from 'konva/lib/types';

type Anchor = keyof ExtendedAnchoringOptions;

export interface ProductTransform {
  rotation: number | undefined;
  scaleY: number;
  scaleX: number;
  x: number | undefined;
  y: number | undefined;
  width: number;
  height: number;
}

const VALID_TYPES = [
  BaseType.GLASS_GLASS_90,
  BaseType.GLASS_GLASS_180,
  BaseType.GLASS_GLASS_180_4,
];

const GLASS_GLASS_MOUNT_TYPE_90_STRINGS = [
  'Szkło - szkło 90',
  'Glass - glass 90',
  'Vetro - vetro 90',
  'Стекло - стекло 90',
];

const GLASS_GLASS_MOUNT_TYPE_180_STRINGS = [
  'Szkło - szkło 180',
  'Glass - glass 180',
  'Vetro - vetro 180',
  'Стекло - стекло 180',
];

const selectOppositeAnchor = (
  target: GlassTarget,
  neighbor: Neighbor,
  ratio = 1,
): Anchor => {
  const corners = getCornerPoints(scaleShape(target.glass, ratio));
  const neighborCorners = getCornerPoints(neighbor.shape);
  switch (target.anchor) {
    case 'right': {
      return 'left';
    }
    case 'left': {
      return 'right';
    }
    case 'top': {
      return 'bottom';
    }
    case 'bottom': {
      return 'top';
    }
    case 'topLeft': {
      const { topLeft } = corners;
      const { bottomRight } = neighborCorners;
      const tlX = new Big(topLeft.x);
      const tlY = new Big(topLeft.y);
      const brX = new Big(bottomRight.x);
      const brY = new Big(bottomRight.y);
      if (brX.lte(tlX) && brY.lte(tlY)) {
        return 'bottomRight';
      }
      if (brX.lte(tlX) && brY.gte(tlY)) {
        return 'topRight';
      }
      if (brX.gte(tlX) && brY.lte(tlY)) {
        return 'bottomLeft';
      }
      return 'bottomRight';
    }
    case 'topRight': {
      const { topRight } = corners;
      const { bottomLeft } = neighborCorners;
      const trX = new Big(topRight.x);
      const trY = new Big(topRight.y);
      const blX = new Big(bottomLeft.x);
      const blY = new Big(bottomLeft.y);
      if (blX.gte(trX) && blY.lte(trY)) {
        return 'bottomLeft';
      }
      if (blX.gte(trX) && blY.gte(trY)) {
        return 'topLeft';
      }
      if (blX.lte(trX) && blY.lte(trY)) {
        return 'bottomRight';
      }
      return 'topRight';
    }
    case 'bottomRight': {
      const { bottomRight } = corners;
      const { topLeft } = neighborCorners;
      const brX = new Big(bottomRight.x);
      const brY = new Big(bottomRight.y);
      const tlX = new Big(topLeft.x);
      const tlY = new Big(topLeft.y);
      if (tlX.gte(brX) && tlY.gte(brY)) {
        return 'topLeft';
      }
      if (tlX.gte(brX) && tlY.lte(brY)) {
        return 'bottomLeft';
      }
      if (tlX.lte(brX) && tlY.gte(brY)) {
        return 'topRight';
      }
      return 'bottomRight';
    }
    case 'bottomLeft': {
      const { bottomLeft } = corners;
      const { topRight } = neighborCorners;
      const blX = new Big(bottomLeft.x);
      const blY = new Big(bottomLeft.y);
      const trX = new Big(topRight.x);
      const trY = new Big(topRight.y);
      if (trX.lte(blX) && trY.gte(blY)) {
        return 'topRight';
      }
      if (trX.gte(blX) && trY.gte(blY)) {
        return 'topLeft';
      }
      if (trX.lte(blX) && trY.lte(blY)) {
        return 'bottomRight';
      }
      return 'bottomLeft';
    }
    default: {
      return 'top';
    }
  }
};

const composeCornerGaps = (
  gaps?: GlassDimensions,
  direction?: DiagonalDirection,
): BigVector => {
  if (gaps && direction) {
    if (direction === 'topLeft') {
      return { x: new Big(gaps.left), y: new Big(gaps.top) };
    }
    if (direction === 'topRight') {
      return { x: new Big(gaps.right), y: new Big(gaps.top) };
    }
    if (direction === 'bottomRight') {
      return { x: new Big(gaps.right), y: new Big(gaps.bottom) };
    }
    if (direction === 'bottomLeft') {
      return { x: new Big(gaps.left), y: new Big(gaps.bottom) };
    }
  }
  const nullish = new Big(0);
  return { x: nullish, y: nullish };
};

const checkCollision = (
  validNeighbors: Neighbor[],
  itemShape: Shape,
  collisionDirection: Axis[],
) => {
  return validNeighbors.filter((glass) => {
    if (!glass.shape.corners) {
      return false;
    }
    const glassShape = new Shape(
      { corners: glass.shape.corners },
      glass.shape.position,
    );

    return (
      collisionDirection.map((direction) =>
        glassShape.shapeCollision(itemShape, direction),
      ).length > 0
    );
  });
};

export const findConnections = (
  dropTarget: DropTarget,
  shape: ShapeType,
  glassNeighbors: Record<number, GlassNeighbors>,
  productCode: string,
  isGlassGlass?: boolean,
  glassGlassType?: GlassGlassType,
  glassProperties?: GlassPropertiesSearch,
  ratio = 1,
): Connection[] => {
  let connections: Connection[] = [
    {
      type: dropTarget.type,
      targetId: dropTarget.targetId,
      anchor: dropTarget.anchor,
      position: shape.position,
    },
  ];
  if (isGlassGlass || productCode == 'PD82SSS') {
    let neighbors: Neighbor[] = [];
    let gap = new Big(0);
    let cornerGaps = composeCornerGaps();
    const neighborsMap = glassNeighbors[(dropTarget as GlassTarget).glass.id];
    switch (dropTarget.anchor) {
      case 'right':
      case 'left':
      case 'bottom':
      case 'top': {
        neighbors = neighborsMap[dropTarget.anchor];
        gap = new Big(
          glassProperties?.glassToGlassGap?.[dropTarget.anchor] ?? 0,
        );

        if (isGlassGlass && dropTarget.type === ConnectionType.GLASS) {
          const oppositeAnchor = selectOppositeAnchor(
            dropTarget,
            neighborsMap[dropTarget.anchor][0],
          );

          gap = gap.plus(
            new Big(glassProperties?.glassToGlassGap?.[oppositeAnchor] ?? 0),
          );
        }

        break;
      }
      case 'topLeft': {
        neighbors = [
          ...neighborsMap.top,
          ...neighborsMap.left,
          ...neighborsMap.topLeft,
        ];
        cornerGaps = composeCornerGaps(
          glassProperties?.glassToGlassGap,
          dropTarget.anchor,
        );
        break;
      }
      case 'topRight': {
        neighbors = [
          ...neighborsMap.top,
          ...neighborsMap.right,
          ...neighborsMap.topRight,
        ];
        cornerGaps = composeCornerGaps(
          glassProperties?.glassToGlassGap,
          dropTarget.anchor,
        );
        break;
      }
      case 'bottomRight': {
        neighbors = [
          ...neighborsMap.bottom,
          ...neighborsMap.right,
          ...neighborsMap.bottomRight,
        ];
        cornerGaps = composeCornerGaps(
          glassProperties?.glassToGlassGap,
          dropTarget.anchor,
        );
        break;
      }
      case 'bottomLeft': {
        neighbors = [
          ...neighborsMap.bottom,
          ...neighborsMap.left,
          ...neighborsMap.bottomLeft,
        ];
        cornerGaps = composeCornerGaps(
          glassProperties?.glassToGlassGap,
          dropTarget.anchor,
        );
        break;
      }
    }

    const itemShape = new Shape({
      corners: getCoordinatesForRect(shape.width, shape.height, shape.position),
    });
    const validNeighbors = neighbors
      .filter((item) => item.data.type === NeighborType.GLASS)
      .filter((glass) =>
        glassGlassType === GlassGlassType.T180_4
          ? new Big(glass.distance).lte(new Big(normalizeVector(cornerGaps)))
          : new Big(glass.distance).lte(gap),
      );

    let collided: Neighbor[] = [];
    if (glassGlassType === GlassGlassType.T180_4) {
      collided = checkCollision(validNeighbors, itemShape, ['x', 'y']);
    } else {
      const collisionDirection =
        dropTarget.anchor === 'left' || dropTarget.anchor === 'right'
          ? 'y'
          : 'x';

      collided = checkCollision(validNeighbors, itemShape, [
        collisionDirection,
      ]);

      if (collided.length < 1) {
        validNeighbors[0] && collided.push(validNeighbors[0]);
      }
    }

    // List of products for which there is a bug when finding a connection
    // Only glass on which the product has been dropped should be added to the lists (this glass is added in line 237)
    // THIS IS ONLY A TEMPORARY FIX!
    const PRODUCT_CODES_SKIP_FIND_CONNECTIONS = [
      'PD40SSS',
      'PD40PSS',
      'PD40-OSRH SETSSS',
      'PD40-OSLH SETSSS',
      'PD40NA',
      'PD40NABL',
    ];

    const skipFindConnections = PRODUCT_CODES_SKIP_FIND_CONNECTIONS.includes(
      productCode,
    );

    if (!skipFindConnections) {
      connections = [
        ...connections,
        ...collided.map((item) => ({
          type: dropTarget.type,
          targetId: String((item.data as NeighborGlass).glass.id),
          anchor:
            dropTarget.type === ConnectionType.GLASS
              ? selectOppositeAnchor(dropTarget, item, ratio)
              : dropTarget.anchor,
          position: shape.position,
        })),
      ];
    }
  }

  return connections;
};

export const setProductOrientation = (
  target: DropTarget,
): ProductOrientation => {
  switch (target.anchor) {
    case 'right': {
      return ProductOrientation.RIGHT;
    }
    case 'bottom': {
      return ProductOrientation.BOTTOM;
    }
    case 'top': {
      return ProductOrientation.TOP;
    }
    default:
    case 'left': {
      return ProductOrientation.LEFT;
    }
  }
};

interface MapProductSearchProps {
  product: ProductSearch;
  shape: ShapeType;
  dropTarget: DropTarget;
  glassNeighbors: Record<number, GlassNeighbors>;
  customWidth?: number;
  rotated?: boolean;
  isGlassGlass?: boolean;
  glassGlassType?: GlassGlassType;
  glassProperties?: GlassPropertiesSearch;
  ratio?: number;
  getUsedProductsWithProjectProducts?: GetGlassProductsFunc;
  hasGlassInDirection?: HasGlassInDirectionFunc;
  bounds?: Bounds;
}

const checkSymmetry = (
  symmetry: SymetryOptions,
  anchor: keyof ExtendedAnchoringOptions,
) => {
  const { vertical, horizontal, oppositeSide, reversibility } = symmetry;

  const values = {
    vertical: false,
    horizontal: false,
    reversibility,
    oppositeSide,
  };

  const rotationAnchorsVertical = oppositeSide
    ? ['left', 'bottomLeft', 'topLeft']
    : ['right', 'bottomRight', 'topRight'];

  const rotationAnchorsHorizontal = oppositeSide
    ? ['top', 'topLeft', 'topRight']
    : ['bottom', 'bottomLeft', 'bottomRight'];

  if (rotationAnchorsVertical.includes(anchor)) {
    return { ...values, vertical };
  } else if (rotationAnchorsHorizontal.includes(anchor)) {
    return { ...values, horizontal };
  } else {
    return values;
  }
};

const calculateSlope = (
  mountedEdgePoints: [[number, number], [number, number]] | undefined,
  anchor: keyof ExtendedAnchoringOptions,
) => {
  if (!mountedEdgePoints) {
    return 0;
  }

  const isHorizontal = anchor === 'left' || anchor === 'right';

  return sectionAngle(...mountedEdgePoints) - (isHorizontal ? 90 : 0);
};

const calculateAnchorRotation = (
  baseType: BaseType,
  anchor: keyof ExtendedAnchoringOptions,
) => {
  if (baseType === 'CONNECTOR_WALL' && anchor === 'topRight') {
    return 180;
  }

  switch (anchor) {
    case 'top': {
      return 90;
    }
    case 'right': {
      return 180;
    }
    case 'bottom': {
      return 270;
    }
    default: {
      return 0;
    }
  }
};

export const calculateProductRotation = (
  baseType: BaseType,
  shapeDimensions: { width: number; height: number },
  anchor: keyof ExtendedAnchoringOptions,
  symmetryOptions: SymetryOptions,
  mountedEdgePoints: [[number, number], [number, number]] | undefined,
): ProductTransform => {
  const symmetry = checkSymmetry(symmetryOptions, anchor);

  const anchorRotation = calculateAnchorRotation(baseType, anchor);
  const slope = calculateSlope(mountedEdgePoints, anchor);

  if (baseType === BaseType.GASKET || baseType === BaseType.BAR) {
    return transformProduct(0, slope, shapeDimensions, {
      vertical: false,
      horizontal: false,
      reversibility: false,
      oppositeSide: false,
    });
  }

  return transformProduct(anchorRotation, slope, shapeDimensions, symmetry);
};

export const mapProductSearch = ({
  product,
  shape,
  glassNeighbors,
  dropTarget,
  isGlassGlass,
  glassGlassType,
  glassProperties,
  customWidth,
  ratio = 1,
}: MapProductSearchProps): ProductModel => {
  let shapeDimensions;

  if (
    product.attributes.symmetry.reversibility &&
    (dropTarget.anchor === 'top' || dropTarget.anchor === 'bottom')
  ) {
    shapeDimensions = {
      height: customWidth ?? shape.width,
      width: shape.height,
    };
  } else {
    shapeDimensions = {
      width: customWidth ?? shape.width,
      height: shape.height,
    };
  }

  let corners = getCoordinatesForRect(
    shapeDimensions.width,
    shapeDimensions.height,
  );

  let mountedEdgePoints = undefined;

  if (
    dropTarget.type === ConnectionType.GLASS &&
    (dropTarget.anchor === 'top' ||
      dropTarget.anchor === 'bottom' ||
      dropTarget.anchor === 'left' ||
      dropTarget.anchor === 'right')
  ) {
    const targetObj = new Shape(
      { corners: dropTarget.glass.corners },
      dropTarget.glass.position,
    );

    const edges = mapCoordinatesToEdges({ corners: targetObj.corners });

    mountedEdgePoints = edges[dropTarget.anchor];

    const point1 = new Vector2(mountedEdgePoints[0]);
    const point2 = new Vector2(mountedEdgePoints[1]);

    // Calculate the direction vector of the line
    const directionVector = point2.subtract(point1);

    // Normalize the direction vector
    const normalizedDirection = directionVector.normalize().copy();

    // Calculate the perpendicular vector to the line (for width and height)
    const perpendicularVector = new Vector2([
      normalizedDirection.y,
      -normalizedDirection.x,
    ]).copy();

    const normV =
      dropTarget.anchor === 'top' || dropTarget.anchor === 'bottom'
        ? new Vector2([-perpendicularVector.x, -perpendicularVector.y])
        : normalizedDirection;
    const perpV =
      dropTarget.anchor === 'top' || dropTarget.anchor === 'bottom'
        ? normalizedDirection
        : perpendicularVector;

    // Calculate the corner points of the shape
    const topLeft = {
      x: 0,
      y: 0,
    };

    const topRight = {
      x: topLeft.x + shapeDimensions.width * perpV.x.toNumber(),
      y: topLeft.y + shapeDimensions.width * perpV.y.toNumber(),
    };

    const bottomLeft = {
      x: topLeft.x + shapeDimensions.height * normV.x.toNumber(),
      y: topLeft.y + shapeDimensions.height * normV.y.toNumber(),
    };

    const bottomRight = {
      x: bottomLeft.x + shapeDimensions.width * perpV.x.toNumber(),
      y: bottomLeft.y + shapeDimensions.width * perpV.y.toNumber(),
    };

    corners = {
      ...corners,
      'top-left': [topLeft.x, topLeft.y],
      'top-right': [topRight.x, topRight.y],
      'bottom-left': [bottomLeft.x, bottomLeft.y],
      'bottom-right': [bottomRight.x, bottomRight.y],
    };
  }

  return {
    id: String(product.id),
    parentId: dropTarget.targetId,
    position: shape.position,
    width: shapeDimensions.width,
    height: shapeDimensions.height,
    corners: corners,
    thickness: product.thickness,
    productId: product.id,
    orientation: setProductOrientation(dropTarget),
    cutout: product.attributes.cutout,
    rotation: calculateSlope(mountedEdgePoints, dropTarget.anchor),
    connections: findConnections(
      dropTarget,
      shape,
      glassNeighbors,
      product.code,
      isGlassGlass,
      glassGlassType,
      glassProperties,
      ratio,
    ),
    wallToGlassGap: product.wallToGlassGap,
  };
};

const mapWallConnector = (props: MapProductSearchProps) => {
  const { hasGlassInDirection, bounds, dropTarget } = props;

  if (
    hasGlassInDirection !== undefined &&
    bounds !== undefined &&
    dropTarget.type === ConnectionType.GLASS
  ) {
    const verticalOrientation =
      dropTarget.anchor === 'topLeft'
        ? ProductOrientation.LEFT
        : ProductOrientation.RIGHT;

    if (!hasGlassInDirection(dropTarget.glass, verticalOrientation)) {
      if (dropTarget.anchor === 'topLeft') {
        props.shape.position.x = bounds.topLeft.x;
      } else {
        props.shape.position.x = fixValue(
          new Big(bounds.bottomRight.x).minus(props.shape.width),
          5,
        );
      }
    }
  }

  // Wall connector can be placed only on top corners
  // Select vertical edge from corner
  // Find distance to wall
  // Find gap that matches distance
  // If gap === distance then snap to wall
  // else - do nothing

  return mapProductSearch(props);
};

export const getBarSize = (
  glass: Glass,
  productDimensions: Dimensions,
  anchor: Anchor,
) => {
  const edges = mapCoordinatesToEdges({ corners: glass.originCorners });

  switch (anchor) {
    case 'bottom':
    case 'top': {
      const edgePoints = edges[anchor];

      return {
        width: pointsDistance(...edgePoints),
        height: productDimensions.height,
      };
    }
    case 'left':
    case 'right': {
      const edgePoints = edges[anchor];

      return {
        width: productDimensions.height,
        height: pointsDistance(...edgePoints),
      };
    }
    default:
      throw new Error('Invalid anchor!');
  }
};

//TODO: Refactor this terrible code
export const mapBar = (
  product: ProductSearch,
  shape: ShapeType,
  dropTarget: DropTarget,
  glassNeighbors: Record<number, GlassNeighbors>,
  isGlassGlass: boolean,
): ProductModel => {
  let corners = getCoordinatesForRect(shape.width, shape.height);
  let mountedEdgePoints = undefined;
  if (
    dropTarget.type === ConnectionType.GLASS &&
    (dropTarget.anchor === 'top' ||
      dropTarget.anchor === 'bottom' ||
      dropTarget.anchor === 'left' ||
      dropTarget.anchor === 'right')
  ) {
    const targetObj = new Shape(
      { corners: dropTarget.glass.corners },
      dropTarget.glass.position,
    );

    const edges = mapCoordinatesToEdges({ corners: targetObj.corners });
    const edgePoints = edges[dropTarget.anchor];

    mountedEdgePoints = edgePoints;
    edgePoints[1][0] = edgePoints[1][0] - edgePoints[0][0];
    edgePoints[1][1] = edgePoints[1][1] - edgePoints[0][1];
    edgePoints[0][0] = 0;
    edgePoints[0][1] = 0;

    const isHorizontal =
      dropTarget.anchor === 'left' || dropTarget.anchor === 'right';

    const width = isHorizontal ? shape.width : 0;
    const height = isHorizontal ? 0 : shape.height;

    const shapeObj = new Shape({
      corners: {
        'top-left': edgePoints[0],
        'top-right': [
          edgePoints[isHorizontal ? 0 : 1][0] + width,
          edgePoints[isHorizontal ? 0 : 1][1],
        ],
        'bottom-right': [edgePoints[1][0] + width, edgePoints[1][1] + height],
        'bottom-left': [
          edgePoints[isHorizontal ? 1 : 0][0],
          edgePoints[isHorizontal ? 1 : 0][1] + height,
        ],
      },
    });

    corners = shapeObj.corners;
  }

  return {
    id: String(product.id),
    position: { ...shape.position },
    parentId: dropTarget.targetId,
    width: shape.width,
    height: shape.height,
    corners,
    rotation: calculateSlope(mountedEdgePoints, dropTarget.anchor),
    thickness: product.thickness,
    productId: product.id,
    orientation: setProductOrientation(dropTarget),
    cutout: product.attributes.cutout,
    connections: findConnections(
      dropTarget,
      shape,
      glassNeighbors,
      product.code,
      isGlassGlass,
    ),
    wallToGlassGap: product.wallToGlassGap,
  };
};

export const filterProductsByGlass = (
  glassId: number,
  products: ProductModel[],
) =>
  products.filter((product) =>
    product.connections.some(
      (connection) =>
        connection.type === ConnectionType.GLASS &&
        connection.targetId === String(glassId),
    ),
  );

export const mapPlacedProduct = (
  data: ProductSearch,
  validShape: ShapeType,
  target: GlassTarget,
  isGlassGlass: boolean,
  ratio: number,
  glassNeighbors: Record<number, GlassNeighbors>,
  glassGlassType?: GlassGlassType | undefined,
  glassProperties?: GlassPropertiesSearch,
  hasGlassInDirection?: HasGlassInDirectionFunc,
  bounds?: Bounds,
) => {
  switch (data.baseType) {
    case BaseType.GASKET:
    case BaseType.BAR:
      return mapBar(data, validShape, target, glassNeighbors, isGlassGlass);
    case BaseType.CONNECTOR_WALL:
      return mapWallConnector({
        product: data,
        shape: validShape,
        dropTarget: target,
        customWidth: undefined,
        rotated:
          data.attributes.symmetry.reversibility &&
          (target.anchor === 'top' || target.anchor === 'bottom'),
        glassNeighbors,
        isGlassGlass,
        glassGlassType,
        glassProperties,
        ratio,
        hasGlassInDirection,
        bounds,
      });
    default:
      return mapProductSearch({
        product: data,
        shape: validShape,
        dropTarget: target,
        customWidth: undefined,
        rotated:
          data.attributes.symmetry.reversibility &&
          (target.anchor === 'top' || target.anchor === 'bottom'),
        glassNeighbors,
        isGlassGlass,
        glassGlassType,
        glassProperties,
        ratio,
      });
  }
};

export const transformProduct = (
  rotationDeg: 0 | 90 | 180 | 270 | undefined,
  anchorRotationDeg: number | undefined,
  shapeDimensions: {
    width: number;
    height: number;
  },
  symmetry?: SymetryOptions,
) => {
  const { width, height: baseHeight } = shapeDimensions;

  let values: ProductTransform = {
    rotation: 0,
    scaleY: 1,
    scaleX: 1,
    x: 0,
    y: 0,
    width: width,
    height: baseHeight,
  };

  if (!symmetry) {
    return values;
  }

  const { vertical, horizontal, reversibility } = symmetry;

  const height = baseHeight;

  if (horizontal) {
    values = {
      ...values,
      scaleY: -1,
      y: height,
    };
  }
  if (vertical) {
    values = {
      ...values,
      scaleX: -1,
      x: width,
    };
  }

  if (rotationDeg && reversibility) {
    switch (rotationDeg) {
      case 90: {
        values = {
          ...values,
          width: height,
          height: width,
          rotation: 270,
          scaleX: -1,
        };
        break;
      }
      case 270: {
        values = {
          ...values,
          width: height,
          height: width,
          rotation: rotationDeg,
          y: vertical ? undefined : height,
        };
        break;
      }
    }
  }

  if (anchorRotationDeg) {
    values.rotation = (values.rotation ?? 0) + anchorRotationDeg;
  }

  return values;
};

export const checkIfGlassGlass = (
  baseType: BaseType,
  mountTypes: ProductSearch['mountTypes'],
) => {
  return (
    VALID_TYPES.includes(baseType) ||
    //TODO: Terrible solution. Mount types should be const enum not strings.
    (baseType === BaseType.GASKET &&
      some(
        mountTypes,
        (type) =>
          some(
            GLASS_GLASS_MOUNT_TYPE_90_STRINGS,
            (constType) => constType === type,
          ) ||
          some(
            GLASS_GLASS_MOUNT_TYPE_180_STRINGS,
            (constType) => constType === type,
          ),
      ))
  );
};

export const checkIfGlassGlass180 = (
  baseType: BaseType,
  mountTypes: ProductSearch['mountTypes'],
) => {
  return (
    (baseType !== BaseType.GLASS_GLASS_90 && VALID_TYPES.includes(baseType)) ||
    (baseType === BaseType.GASKET &&
      some(mountTypes, (type) =>
        some(
          GLASS_GLASS_MOUNT_TYPE_180_STRINGS,
          (constType) => constType === type,
        ),
      ))
  );
};

export const checkGlassGlassType = (
  baseType: BaseType,
  mountTypes: ProductSearch['mountTypes'],
): GlassGlassType | undefined => {
  if (checkIfGlassGlass(baseType, mountTypes)) {
    if (baseType === BaseType.GLASS_GLASS_180) {
      return GlassGlassType.T180;
    }
    if (baseType === BaseType.GLASS_GLASS_90) {
      return GlassGlassType.T90;
    }
    if (baseType === BaseType.GLASS_GLASS_180_4) {
      return GlassGlassType.T180_4;
    }
  }
};

export const getProductCenterPoint = (shape: ShapeType) => {
  let center: Vector2d;

  if (shape.corners) {
    const shapeObj = new Shape(
      { corners: shape.corners },
      scalePosition(shape.position, true),
    );

    center = {
      x: shapeObj.polygonCentroid.x,
      y: shapeObj.polygonCentroid.y,
    };
  } else {
    center = {
      x: shape.position.x + shape.width / 2,
      y: shape.position.y + shape.height / 2,
    };
  }

  return toPoint(center);
};

export const moveProduct = (
  mountedEdgePoints: [[number, number], [number, number]] | undefined,
  productData: ProductModel,
  positionChange: { x?: Big; y?: Big },
) => {
  if (!positionChange.x && !positionChange.y) {
    return productData;
  }

  if (
    mountedEdgePoints &&
    mountedEdgePoints[0][0] !== mountedEdgePoints[1][0] &&
    mountedEdgePoints[0][1] !== mountedEdgePoints[1][1]
  ) {
    const line = findLine(mountedEdgePoints[0], mountedEdgePoints[1]);
    const parallel = findParallelLine(toPoint(productData.position), line);

    if (productData.orientation === 'RIGHT') {
      if (line.a < 0) {
        positionChange.y = positionChange.y?.mul(-1);
      }
    }

    if (productData.orientation === 'LEFT') {
      if (line.a < 0) {
        positionChange.y = positionChange.y?.mul(-1);
      }
    }

    const newPoint = findPointOnLineInDistance(
      parallel,
      toPoint(productData.position),
      (positionChange.x ? positionChange.x : positionChange.y) as Big,
    );

    return {
      ...productData,
      position: toVector(newPoint),
    };
  }

  let modifiedPosition = productData.position;

  if (positionChange.x) {
    modifiedPosition = {
      ...modifiedPosition,
      x: fixValue(new Big(productData.position.x).plus(positionChange.x), 5),
    };
  }

  if (positionChange.y) {
    modifiedPosition = {
      ...modifiedPosition,
      y: fixValue(new Big(productData.position.y).plus(positionChange.y), 5),
    };
  }

  return {
    ...productData,
    position: modifiedPosition,
  };
};
