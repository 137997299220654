import { useMemo } from 'react';
import { ConnectionType } from '../../../../services/api/models/connection';
import { Glass } from '../../../../services/api/models/glass';
import { ProductModel } from '../../../../services/api/models/product';
import { mapCoordinatesToEdges } from '../../../../utils/shape';
import { Shape } from '../../../space';

interface Props {
  data: ProductModel;
  glasses: Glass[];
}

const useProduct = ({ data, glasses }: Props) => {
  const parentConnections = useMemo(
    () =>
      data.connections.filter(
        ({ type }) =>
          type === ConnectionType.GLASS || type === ConnectionType.PRODUCT,
      ),
    [data.connections],
  );

  const parentConnection = useMemo(() => {
    if (data.parentId) {
      return parentConnections.filter(
        (conn) => conn.targetId === data.parentId,
      )[0];
    }

    return parentConnections[0];
  }, [data.parentId, parentConnections]);

  const glassParents = useMemo(
    () =>
      parentConnections
        .filter((connection) => connection.type === ConnectionType.GLASS)
        .map((connection) =>
          glasses.find((glass) => String(glass.id) === connection.targetId),
        )
        .filter(Boolean),
    [glasses, parentConnections],
  );

  const glassParent = useMemo(
    () =>
      parentConnection.type === ConnectionType.GLASS
        ? glasses.find(
            (glass) => String(glass.id) === parentConnection.targetId,
          )
        : undefined,
    [glasses, parentConnection.targetId, parentConnection.type],
  );

  const mountedEdgePoints = useMemo(() => {
    if (
      !glassParent ||
      (parentConnection.anchor !== 'top' &&
        parentConnection.anchor !== 'bottom' &&
        parentConnection.anchor !== 'left' &&
        parentConnection.anchor !== 'right')
    ) {
      return undefined;
    }

    const glass = new Shape(
      { corners: glassParent.corners },
      glassParent.position,
    );

    const edges = mapCoordinatesToEdges({ corners: glass.corners });
    return edges[parentConnection.anchor];
  }, [glassParent, parentConnection.anchor]);

  return {
    glassParent,
    glassParents,
    parentConnection,
    mountedEdgePoints,
  };
};

export default useProduct;
