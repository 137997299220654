import { Distance, Shape as ShapeType } from '../types';
import { fixValue } from './fix';
import { Glass } from '../../../services/api/models/glass';
import { Shape } from '../../space';
import { scalePosition, toPoint, toVector } from '../../../utils/shape';
import { Vector2d } from 'konva/lib/types';
import { Vector2 } from './vector';
import { findPointDistanceFromVector } from './geometry/vectors';
import {
  findCrossPoint,
  findDistanceBetweenPoints,
  findLine,
  findParallelLine,
  findPerpendicularLine,
  isLinePerpendicularToXAxis,
  Line,
} from './geometry/lines';
import Big from 'big.js';
import { getProductCenterPoint } from './product';

const getShapeEdgeMiddlePoints = (shape: ShapeType) => {
  const shapePosition = scalePosition(shape.position, true);

  if (shape.corners) {
    const shapeObj = new Shape({ corners: shape.corners }, shapePosition);

    return {
      top: {
        x:
          (shapeObj.topEdge.points.left.x + shapeObj.topEdge.points.right.x) *
          0.5,
        y:
          (shapeObj.topEdge.points.left.y + shapeObj.topEdge.points.right.y) *
          0.5,
      },
      bottom: {
        x:
          (shapeObj.bottomEdge.points.left.x +
            shapeObj.bottomEdge.points.right.x) *
          0.5,
        y:
          (shapeObj.bottomEdge.points.left.y +
            shapeObj.bottomEdge.points.right.y) *
          0.5,
      },
      left: {
        x:
          (shapeObj.leftEdge.points.top.x + shapeObj.leftEdge.points.bottom.x) *
          0.5,
        y:
          (shapeObj.leftEdge.points.top.y + shapeObj.leftEdge.points.bottom.y) *
          0.5,
      },
      right: {
        x:
          (shapeObj.rightEdge.points.top.x +
            shapeObj.rightEdge.points.bottom.x) *
          0.5,
        y:
          (shapeObj.rightEdge.points.top.y +
            shapeObj.rightEdge.points.bottom.y) *
          0.5,
      },
    };
  } else {
    return {
      top: { x: shapePosition.x, y: shapePosition.y },
      bottom: {
        x: shapePosition.x + shape.width,
        y: shapePosition.y + shape.height,
      },
      left: { x: shapePosition.x, y: shapePosition.y + shape.height },
      right: { x: shapePosition.x + shape.width, y: shapePosition.y },
    };
  }
};

export enum MountedEdgeTilt {
  VERTICAL,
  VERTICAL_PERPENDICULAR_X,
  HORIZONTAL,
}

const findMountedEdgeTilt = (
  parentObj: Shape,
  mountedEdgePoints: [[number, number], [number, number]],
): MountedEdgeTilt => {
  if (isLinePerpendicularToXAxis(mountedEdgePoints[0], mountedEdgePoints[1])) {
    return MountedEdgeTilt.VERTICAL_PERPENDICULAR_X;
  }

  const mountedEdge = findLine(mountedEdgePoints[0], mountedEdgePoints[1]);

  const glassTLCorner = toPoint(parentObj.leftEdge.points.top);
  const glassBLCorner = toPoint(parentObj.leftEdge.points.bottom);
  const glassTRCorner = toPoint(parentObj.rightEdge.points.top);
  const glassBRCorner = toPoint(parentObj.rightEdge.points.bottom);

  if (!isLinePerpendicularToXAxis(glassTLCorner, glassBLCorner)) {
    const leftLine = findLine(
      toPoint(parentObj.leftEdge.points.top),
      toPoint(parentObj.leftEdge.points.bottom),
    );

    if (leftLine.a === mountedEdge.a) {
      return MountedEdgeTilt.VERTICAL;
    }
  }
  if (!isLinePerpendicularToXAxis(glassTRCorner, glassBRCorner)) {
    const rightLine = findLine(
      toPoint(parentObj.rightEdge.points.top),
      toPoint(parentObj.rightEdge.points.bottom),
    );

    if (rightLine.a === mountedEdge.a) {
      return MountedEdgeTilt.VERTICAL;
    }
  }
  return MountedEdgeTilt.HORIZONTAL;
};

const findVerticalPerpendicularLineDistance = (
  points: { top: Vector2d; bottom: Vector2d },
  productCenter: [number, number],
) => {
  if (points.top.x === points.bottom.x) {
    return Math.abs(productCenter[0] - points.top.x);
  }

  const line = findLine(toPoint(points.top), toPoint(points.bottom));

  const linePerpendicular = findPerpendicularLine(productCenter, line);

  const lineCrossPoint = findCrossPoint(linePerpendicular, line);

  return findDistanceBetweenPoints(productCenter, lineCrossPoint);
};

const findHorizontalPerpendicularLineDistance = (
  points: { left: Vector2d; right: Vector2d },
  productCenter: [number, number],
) => {
  if (points.left.y === points.right.y) {
    return Math.abs(productCenter[1] - points.left.y);
  }

  const line = findLine(toPoint(points.left), toPoint(points.right));

  const linePerpendicular = findPerpendicularLine(productCenter, line);

  const lineCrossPoint = findCrossPoint(linePerpendicular, line);

  return findDistanceBetweenPoints(productCenter, lineCrossPoint);
};

const findVerticalLineDistance = (
  points: { top: Vector2d; bottom: Vector2d },
  mountedEdgeParallelCenter: Line,
  productCenter: [number, number],
) => {
  if (points.top.x === points.bottom.x) {
    return Math.abs(productCenter[0] - points.top.x);
  }

  const line = findLine(toPoint(points.top), toPoint(points.bottom));

  const lineCrossPoint = findCrossPoint(mountedEdgeParallelCenter, line);

  return findDistanceBetweenPoints(productCenter, lineCrossPoint);
};

const findHorizontalLineDistance = (
  points: { left: Vector2d; right: Vector2d },
  mountedEdgeParallelCenter: Line,
  productCenter: [number, number],
) => {
  const line = findLine(toPoint(points.left), toPoint(points.right));

  const lineCrossPoint = findCrossPoint(mountedEdgeParallelCenter, line);

  return findDistanceBetweenPoints(productCenter, lineCrossPoint);
};

export const findEdgeDistance = (
  shape: ShapeType,
  parent: Glass,
  mountedEdgePoints: [[number, number], [number, number]] | undefined,
): Distance => {
  const parentObj = new Shape({ corners: parent.corners }, parent.position);
  const shapePoints = getShapeEdgeMiddlePoints(shape);

  if (mountedEdgePoints !== undefined) {
    const mountedEdgeTilt = findMountedEdgeTilt(parentObj, mountedEdgePoints);

    if (mountedEdgeTilt === MountedEdgeTilt.VERTICAL) {
      const mountedEdge = findLine(mountedEdgePoints[0], mountedEdgePoints[1]);
      const mountedEdgeParallelCenter = findParallelLine(
        toPoint(shapePoints.top),
        mountedEdge,
      );

      const leftDistance = findVerticalPerpendicularLineDistance(
        parentObj.leftEdge.points,
        toPoint(shapePoints.left),
      );

      const rightDistance = findVerticalPerpendicularLineDistance(
        parentObj.rightEdge.points,
        toPoint(shapePoints.right),
      );

      const topDistance = findHorizontalLineDistance(
        parentObj.topEdge.points,
        mountedEdgeParallelCenter,
        toPoint(shapePoints.top),
      );

      const bottomDistance = findHorizontalLineDistance(
        parentObj.bottomEdge.points,
        mountedEdgeParallelCenter,
        toPoint(shapePoints.bottom),
      );

      return {
        top: fixValue(topDistance, 1),
        bottom: fixValue(bottomDistance, 1),
        left: fixValue(leftDistance, 1),
        right: fixValue(rightDistance, 1),
      };
    }

    if (mountedEdgeTilt === MountedEdgeTilt.HORIZONTAL) {
      const mountedEdge = findLine(mountedEdgePoints[0], mountedEdgePoints[1]);
      const mountedEdgeParallelCenter = findParallelLine(
        toPoint(shapePoints.top),
        mountedEdge,
      );

      const leftDistance = findVerticalLineDistance(
        parentObj.leftEdge.points,
        mountedEdgeParallelCenter,
        toPoint(shapePoints.left),
      );

      const rightDistance = findVerticalLineDistance(
        parentObj.rightEdge.points,
        mountedEdgeParallelCenter,
        toPoint(shapePoints.right),
      );

      const topDistance = findHorizontalPerpendicularLineDistance(
        parentObj.topEdge.points,
        toPoint(shapePoints.top),
      );

      const bottomDistance = findHorizontalPerpendicularLineDistance(
        parentObj.bottomEdge.points,
        toPoint(shapePoints.bottom),
      );

      return {
        top: fixValue(topDistance, 1),
        bottom: fixValue(bottomDistance, 1),
        left: fixValue(leftDistance, 1),
        right: fixValue(rightDistance, 1),
      };
    }
  }

  const topDistance = findPointDistanceFromVector(shapePoints.top, [
    parentObj.topEdge.points.left,
    parentObj.topEdge.points.right,
  ]);
  const bottomDistance = findPointDistanceFromVector(shapePoints.bottom, [
    parentObj.bottomEdge.points.left,
    parentObj.bottomEdge.points.right,
  ]);
  const leftDistance = findPointDistanceFromVector(shapePoints.left, [
    parentObj.leftEdge.points.top,
    parentObj.leftEdge.points.bottom,
  ]);
  const rightDistance = findPointDistanceFromVector(shapePoints.right, [
    parentObj.rightEdge.points.top,
    parentObj.rightEdge.points.bottom,
  ]);

  const top =
    new Vector2([topDistance.x, topDistance.y]).length().toNumber() *
    (topDistance.y < 0 ? -1 : 1);
  const bottom =
    new Vector2([bottomDistance.x, bottomDistance.y]).length().toNumber() *
    (bottomDistance.y > 0 ? -1 : 1);
  const left =
    new Vector2([leftDistance.x, leftDistance.y]).length().toNumber() *
    (leftDistance.x < 0 ? -1 : 1);
  const right =
    new Vector2([rightDistance.x, rightDistance.y]).length().toNumber() *
    (rightDistance.x > 0 ? -1 : 1);

  return {
    top: fixValue(top, 1),
    bottom: fixValue(bottom, 1),
    left: fixValue(left, 1),
    right: fixValue(right, 1),
  };
};

export const findAxisDistance = (
  shape: ShapeType,
  parent: Glass,
  mountedEdgePoints: [[number, number], [number, number]] | undefined,
): Distance => {
  const parentObj = new Shape({ corners: parent.corners }, parent.position);
  const productCenter = getProductCenterPoint(shape);

  if (mountedEdgePoints !== undefined) {
    const mountedEdgeTilt = findMountedEdgeTilt(parentObj, mountedEdgePoints);

    if (mountedEdgeTilt === MountedEdgeTilt.VERTICAL) {
      const mountedEdge = findLine(mountedEdgePoints[0], mountedEdgePoints[1]);
      const mountedEdgeParallelCenter = findParallelLine(
        productCenter,
        mountedEdge,
      );

      const leftDistance = findVerticalPerpendicularLineDistance(
        parentObj.leftEdge.points,
        productCenter,
      );

      const rightDistance = findVerticalPerpendicularLineDistance(
        parentObj.rightEdge.points,
        productCenter,
      );

      const topDistance = findHorizontalLineDistance(
        parentObj.topEdge.points,
        mountedEdgeParallelCenter,
        productCenter,
      );

      const bottomDistance = findHorizontalLineDistance(
        parentObj.bottomEdge.points,
        mountedEdgeParallelCenter,
        productCenter,
      );

      return {
        top: fixValue(topDistance, 1),
        bottom: fixValue(bottomDistance, 1),
        left: fixValue(leftDistance, 1),
        right: fixValue(rightDistance, 1),
      };
    }

    if (mountedEdgeTilt === MountedEdgeTilt.HORIZONTAL) {
      const mountedEdge = findLine(mountedEdgePoints[0], mountedEdgePoints[1]);
      const mountedEdgeParallelCenter = findParallelLine(
        productCenter,
        mountedEdge,
      );

      const leftDistance = findVerticalLineDistance(
        parentObj.leftEdge.points,
        mountedEdgeParallelCenter,
        productCenter,
      );

      const rightDistance = findVerticalLineDistance(
        parentObj.rightEdge.points,
        mountedEdgeParallelCenter,
        productCenter,
      );

      const topDistance = findHorizontalPerpendicularLineDistance(
        parentObj.topEdge.points,
        productCenter,
      );

      const bottomDistance = findHorizontalPerpendicularLineDistance(
        parentObj.bottomEdge.points,
        productCenter,
      );

      return {
        top: fixValue(topDistance, 1),
        bottom: fixValue(bottomDistance, 1),
        left: fixValue(leftDistance, 1),
        right: fixValue(rightDistance, 1),
      };
    }
  }

  const topDistance = findPointDistanceFromVector(toVector(productCenter), [
    parentObj.topEdge.points.left,
    parentObj.topEdge.points.right,
  ]);
  const bottomDistance = findPointDistanceFromVector(toVector(productCenter), [
    parentObj.bottomEdge.points.left,
    parentObj.bottomEdge.points.right,
  ]);
  const leftDistance = findPointDistanceFromVector(toVector(productCenter), [
    parentObj.leftEdge.points.top,
    parentObj.leftEdge.points.bottom,
  ]);
  const rightDistance = findPointDistanceFromVector(toVector(productCenter), [
    parentObj.rightEdge.points.top,
    parentObj.rightEdge.points.bottom,
  ]);

  const top =
    new Vector2([topDistance.x, topDistance.y]).length().toNumber() *
    (topDistance.y < 0 ? -1 : 1);
  const bottom =
    new Vector2([bottomDistance.x, bottomDistance.y]).length().toNumber() *
    (bottomDistance.y > 0 ? -1 : 1);
  const left =
    new Vector2([leftDistance.x, leftDistance.y]).length().toNumber() *
    (leftDistance.x < 0 ? -1 : 1);
  const right =
    new Vector2([rightDistance.x, rightDistance.y]).length().toNumber() *
    (rightDistance.x > 0 ? -1 : 1);

  return {
    top: fixValue(top, 1),
    bottom: fixValue(bottom, 1),
    left: fixValue(left, 1),
    right: fixValue(right, 1),
  };
};

export const scaleDistance = (distance: Distance, ratio: number): Distance => ({
  top: Number(new Big(distance.top).div(ratio).toFixed(2)),
  bottom: Number(new Big(distance.bottom).div(ratio).toFixed(2)),
  left: Number(new Big(distance.left).div(ratio).toFixed(2)),
  right: Number(new Big(distance.right).div(ratio).toFixed(2)),
});
