import Big from 'big.js';
import Konva from 'konva';
import * as React from 'react';
import { useCallback, useEffect, useMemo, useRef } from 'react';
import useImage from 'use-image';
import { ConnectionType } from '../../../../services/api/models/connection';
import { Glass } from '../../../../services/api/models/glass';
import {
  BaseType,
  ProductCutout,
  ProductModel,
} from '../../../../services/api/models/product';
import { useContextMenu } from '../../hooks';
import {
  DEFAULT_RATIO,
  selectMainLayerPosition,
  selectProjection,
  selectScale,
  selectTheme,
  useConfigStore,
} from '../../store/config';
import {
  selectCancel,
  selectConnect,
  selectSubject,
  useConnectStore,
} from '../../store/connect';
import { selectDragData, selectDragging, useDragStore } from '../../store/drag';
import {
  CreatorProduct,
  GlassChild,
  selectDistanceType,
  selectProduct,
  selectUpdateProduct,
  useProductsStore,
} from '../../store/products';
import {
  selectConnectedProducts,
  selectData,
  selectProjectPosition,
  selectUsedProduct,
  useProjectStore,
} from '../../store/project';
import { Projection, Shape } from '../../types';
import { findAxisDistance, findEdgeDistance } from '../../utils/distance';
import { fixPosition, fixValue } from '../../utils/fix';
import { ProductDropZone } from '../ProductDropZone';
import ProductWrapper from './ProductWrapper';
import useProductBounds from './useProductBounds';
import ProductElement from './ProductElement';
import useProduct from './useProduct';
import useProductMinEgdeGap from './useProductMinEgdeGap';
import { getBoundariesExtremes } from '../../utils/boundaries';
import { checkIfGlassGlass, checkIfGlassGlass180 } from '../../utils/product';

export interface Props {
  data: ProductModel;

  onSelect(data: ProductModel, isGlassGlass?: boolean): void;

  onProductUpdate(product: ProductModel): void;

  otherProducts: ProductModel[];
  glasses: Glass[];
  isSelected?: boolean;
  multiselect?: boolean;
}

const CONNECTION_WHITELIST = [
  BaseType.CONNECTOR_WALL,
  BaseType.CONNECTOR_GLASS,
];

const Product: React.FC<Props> = React.memo(
  ({
    data,
    onSelect,
    onProductUpdate,
    otherProducts,
    glasses,
    isSelected,
    multiselect,
  }) => {
    const elementRef = useRef<Konva.Group>(null);
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    const shapeRef = useRef<any>(null);

    const theme = useConfigStore(selectTheme);
    const layerPosition = useConfigStore(selectMainLayerPosition);
    const scale = useConfigStore(selectScale);
    const projection = useConfigStore(selectProjection);
    const { dimensions: templateDimensions } = useProjectStore(selectData);
    const updateCreatorProduct = useProductsStore(selectUpdateProduct);
    const distanceType = useProductsStore(selectDistanceType);
    const currentProduct = useProductsStore(selectProduct(data.id));
    const usedProduct = useProjectStore(selectUsedProduct(data.productId));
    const connected = useProjectStore(selectConnectedProducts(data.id));
    const templatePosition = useProjectStore(selectProjectPosition);
    const dragging = useDragStore(selectDragging);
    const dragData = useDragStore(selectDragData);
    const connectSubject = useConnectStore(selectSubject);
    const connect = useConnectStore(selectConnect);
    const cancelConnect = useConnectStore(selectCancel);
    const [image] = useImage(
      usedProduct?.productPictogramUrl ?? '',
      'anonymous',
    );
    const [topImage] = useImage(
      usedProduct?.productTopPictogramUrl ?? '',
      'anonymous',
    );
    const {
      parentConnection,
      glassParents,
      glassParent,
      mountedEdgePoints,
    } = useProduct({
      data,
      glasses,
    });

    const minGapToEdge = useProductMinEgdeGap({
      glassParent,
      usedProduct,
      parentConnection,
      glasses,
      templatePosition,
      templateDimensions,
    });

    const isGlassGlass180 = useMemo(
      () =>
        !!usedProduct &&
        checkIfGlassGlass180(usedProduct.baseType, usedProduct.mountTypes),
      [checkIfGlassGlass180, usedProduct],
    );
    const isGlassGlass = useMemo(
      () =>
        !!usedProduct &&
        checkIfGlassGlass(usedProduct.baseType, usedProduct.mountTypes),
      [checkIfGlassGlass, usedProduct],
    );

    const { getBoundaries, dragBoundFunc } = useProductBounds({
      elementRef,
      data,
      glassParent,
      glassParents,
      usedProduct,
      isGlassGlass180,
      layerPosition,
      parentConnection,
      otherProducts,
      scale,
      minGapToEdge,
    });

    const { open: openContextMenu } = useContextMenu(
      'product',
      data.id,
      isGlassGlass,
    );
    const { open: openPipeContextmenu } = useContextMenu('pipe', data.id);

    const hasConnection = connected.length > 0;

    const shapeWidth = useMemo(() => fixValue(data.width * DEFAULT_RATIO), [
      data.width,
    ]);

    const shapeHeight = useMemo(() => fixValue(data.height * DEFAULT_RATIO), [
      data.height,
    ]);

    const shapeThickness = useMemo(
      () => (data.thickness ? fixValue(data.thickness * DEFAULT_RATIO) : 0),
      [data.thickness],
    );

    const getCurrentShape = useCallback((cutout?: ProductCutout):
      | Shape
      | undefined => {
      if (elementRef.current && shapeRef.current) {
        const position = elementRef.current.position();
        const size = shapeRef.current.size();

        return {
          position: fixPosition(position, 2),
          width: fixValue(new Big(size.width)),
          height: fixValue(new Big(size.height)),
          corners: data.corners,
          cutout: cutout && {
            ...cutout,
            size: {
              x: fixValue(new Big(cutout.size.x)),
              y: fixValue(new Big(cutout.size.y)),
            },
          },
        };
      }
    }, []);

    const isCornerMounted = useMemo(
      () =>
        parentConnection.anchor === 'topLeft' ||
        parentConnection.anchor === 'bottomLeft' ||
        parentConnection.anchor === 'topRight' ||
        parentConnection.anchor === 'bottomRight',
      [parentConnection],
    );

    const productTopProjection = useMemo(
      () =>
        projection === Projection.TOP && currentProduct && glassParent
          ? {
              ...currentProduct,
              axisDistance: {
                ...(currentProduct as GlassChild).axisDistance,
                top:
                  (glassParent.thickness +
                    (currentProduct.data.thickness ?? 0) / 2) *
                  DEFAULT_RATIO,
              },
              edgeDistance: {
                ...(currentProduct as GlassChild).edgeDistance,
                top: glassParent.thickness * DEFAULT_RATIO,
              },
              data: {
                ...currentProduct.data,
                height: currentProduct.data.thickness ?? 0,
                thickness: currentProduct.data.height,
                position: {
                  ...currentProduct.data.position,
                  y: templatePosition.y + glassParent.thickness * DEFAULT_RATIO,
                },
              },
            }
          : undefined,
      [currentProduct, glassParent, projection, templatePosition.y],
    );

    const countDistance = useCallback(
      (glass: Glass, shape: Shape) => {
        return {
          edgeDistance: findEdgeDistance(shape, glass, mountedEdgePoints),
          axisDistance: findAxisDistance(shape, glass, mountedEdgePoints),
        };
      },
      [mountedEdgePoints],
    );

    const constructCreatorProduct = useCallback(() => {
      let creatorProduct: CreatorProduct | undefined;
      if (parentConnection.type === ConnectionType.GLASS) {
        const shape = getCurrentShape();
        const boundaries = getBoundaries();
        const glassProperties =
          usedProduct &&
          glassParent &&
          usedProduct.glassProperties.find(
            (glass) => glass.thickness === glassParent.thickness,
          );
        if (shape && glassParent && boundaries && glassProperties) {
          creatorProduct = {
            parentType: 'glass',
            data,
            boundaries: getBoundariesExtremes(boundaries),
            id: data.id,
            parent: glassParent,
            allParents: glassParents as Glass[],
            anchor: parentConnection.anchor,
            glassProperties,
            ...countDistance(glassParent, shape),
          };
        }
      }
      if (parentConnection.type === ConnectionType.PRODUCT) {
        creatorProduct = {
          parentType: 'product',
          data,
          id: data.id,
          anchor: 'product',
        };
      }
      return creatorProduct;
    }, [
      countDistance,
      data,
      getBoundaries,
      getCurrentShape,
      glassParents,
      glassParent,
      parentConnection.anchor,
      parentConnection.type,
      usedProduct,
    ]);

    const mayBeConnected = useMemo(() => {
      if (
        connectSubject &&
        usedProduct &&
        CONNECTION_WHITELIST.includes(usedProduct.baseType)
      ) {
        return (
          connectSubject.data.position.y === data.position.y &&
          connectSubject.data.connections.every(
            (connect) => connect.targetId !== data.id,
          )
        );
      }
      return false;
    }, [connectSubject, data.id, data.position.y, usedProduct]);

    const isBar = useMemo(
      () =>
        usedProduct?.baseType === BaseType.GASKET ||
        usedProduct?.baseType === BaseType.BAR,
      [usedProduct?.baseType],
    );

    const allowAnchoring = useMemo(
      () =>
        !hasConnection && !!usedProduct?.attributes.allowAnchoringOtherProducts,
      [hasConnection, usedProduct?.attributes.allowAnchoringOtherProducts],
    );

    const disableDrag = useMemo(
      () =>
        isCornerMounted ||
        hasConnection ||
        multiselect ||
        usedProduct?.baseType === BaseType.PIPE ||
        usedProduct?.baseType === BaseType.GASKET ||
        projection === Projection.TOP,
      [
        hasConnection,
        isCornerMounted,
        multiselect,
        projection,
        usedProduct?.baseType,
      ],
    );

    const stroke = useMemo(
      () => ({
        strokeWidth: isSelected || mayBeConnected ? 1 : 0,
        stroke: mayBeConnected ? theme.colors.success : theme.colors.accentBlue,
      }),
      [
        isSelected,
        mayBeConnected,
        theme.colors.accentBlue,
        theme.colors.success,
      ],
    );

    const properProductPosition = useMemo(() => {
      const productPosition = { ...data.position };
      if (usedProduct?.baseType === BaseType.PIPE && data.productPadding?.top) {
        productPosition.y = productPosition.y - data.productPadding.top;
      }
      return {
        x: productPosition.x * DEFAULT_RATIO,
        y: productPosition.y * DEFAULT_RATIO,
      };
    }, [data, usedProduct?.baseType]);

    const handleDragMove = useCallback(() => {
      const creatorProduct = constructCreatorProduct();
      if (creatorProduct) {
        updateCreatorProduct(creatorProduct);
      }
    }, [constructCreatorProduct, updateCreatorProduct]);

    const handleDragEnd = useCallback(() => {
      if (elementRef.current) {
        const position = elementRef.current.getPosition();
        const scaledPos = fixPosition({
          x: position.x / DEFAULT_RATIO,
          y: position.y / DEFAULT_RATIO,
        });
        const updatedModel = { ...data, position: fixPosition(scaledPos, 5) };
        onProductUpdate(updatedModel);
      }
    }, [data, onProductUpdate]);

    const handleSelect = useCallback(() => {
      if (connectSubject && mayBeConnected) {
        const creatorProduct = constructCreatorProduct();
        creatorProduct && connect(creatorProduct);
      } else {
        connectSubject && cancelConnect();
        onSelect(data, isGlassGlass);
      }
    }, [
      isGlassGlass,
      cancelConnect,
      connect,
      connectSubject,
      constructCreatorProduct,
      data,
      mayBeConnected,
      onSelect,
    ]);

    useEffect(() => {
      const creatorProduct = constructCreatorProduct();
      if (creatorProduct) {
        updateCreatorProduct(creatorProduct);
      }
    }, [data.position, glassParent, updateCreatorProduct]);

    return (
      <>
        {allowAnchoring && dragging && dragData && usedProduct && (
          <ProductDropZone
            iid={data.id}
            product={usedProduct}
            position={properProductPosition}
            width={shapeWidth}
            height={shapeHeight}
            dragData={dragData}
          />
        )}
        <ProductWrapper
          ref={elementRef}
          product={currentProduct}
          position={properProductPosition}
          currentPosition={elementRef.current?.position()}
          distanceType={distanceType}
          parentConnection={parentConnection}
          multiselect={multiselect}
          isBar={isBar}
          isSelected={isSelected}
          draggable={!disableDrag}
          visible={projection === Projection.FRONT}
          onDragEnd={handleDragEnd}
          onDragMove={handleDragMove}
          dragBoundFunc={dragBoundFunc}
          showXAxis
          showYAxis>
          <ProductElement
            shapeRef={shapeRef}
            baseType={usedProduct?.baseType}
            handleSelect={handleSelect}
            image={image}
            topImage={topImage}
            isSelected={isSelected}
            openContextMenu={openContextMenu}
            stroke={stroke}
            symmetryOptions={usedProduct?.attributes.symmetry}
            anchor={parentConnection.anchor}
            edgePoints={mountedEdgePoints}
            shapeWidth={shapeWidth}
            shapeHeight={shapeHeight}
            shapeThickness={shapeThickness}
            openPipeContextmenu={openPipeContextmenu}
            projection={projection}
          />
        </ProductWrapper>
        {projection === Projection.TOP && productTopProjection && (
          <ProductWrapper
            product={productTopProjection}
            position={productTopProjection.data.position}
            currentPosition={elementRef.current?.position()}
            distanceType={distanceType}
            parentConnection={parentConnection}
            multiselect={multiselect}
            isBar={isBar}
            isSelected={isSelected}
            draggable={false}
            showYAxis={false}
            showXAxis>
            <ProductElement
              shapeRef={shapeRef}
              baseType={usedProduct?.baseType}
              handleSelect={handleSelect}
              image={image}
              topImage={topImage}
              isSelected={isSelected}
              openContextMenu={openContextMenu}
              stroke={stroke}
              symmetryOptions={usedProduct?.attributes.symmetry}
              anchor={parentConnection.anchor}
              edgePoints={mountedEdgePoints}
              shapeWidth={shapeWidth}
              shapeHeight={shapeHeight}
              shapeThickness={shapeThickness}
              openPipeContextmenu={openPipeContextmenu}
              projection={projection}
            />
          </ProductWrapper>
        )}
      </>
    );
  },
);

Product.displayName = 'Product';

export default Product;
