import Big from 'big.js';
import * as React from 'react';
import { useCallback, useMemo } from 'react';
import { DistanceTypes } from '../../../../../../modules/creator';
import { CreatorProduct } from '../../../../../../modules/creator/store/products';
import { Boundaries, Distance } from '../../../../../../modules/creator/types';
import { fixValue } from '../../../../../../modules/creator/utils/fix';
import {
  BaseType,
  ProductModel,
  ProductSearch,
} from '../../../../../../services/api/models/product';
import {
  PositioningTypes,
  ProductPosition,
} from '../../components/ProductPosition';
import { EdgeType } from '../../../../../../types';
import { moveProduct } from '../../../../../../modules/creator/utils/product';

interface ProductPositionProps {
  productSearchData: ProductSearch[];
  multiselect?: boolean;
  updateProjectProduct: (data: ProductModel) => void;
  creatorProduct: CreatorProduct;
  distanceType: DistanceTypes;
  onDistanceTypeChange: (type: DistanceTypes) => void;
  minGapToEdge: Partial<Boundaries> | undefined;
  mountedEdgePoints?: [[number, number], [number, number]];
}

const ProductPositioning = ({
  productSearchData,
  multiselect,
  updateProjectProduct,
  creatorProduct,
  distanceType,
  onDistanceTypeChange,
  minGapToEdge,
  mountedEdgePoints,
}: ProductPositionProps) => {
  const isBar = useMemo(() => {
    const item = productSearchData.find(
      (data) => data.id === creatorProduct?.data.productId,
    );
    return (
      item?.baseType === BaseType.GASKET || item?.baseType === BaseType.BAR
    );
  }, [creatorProduct?.data.productId, productSearchData]);

  const changePositioningType = useCallback(
    (type: PositioningTypes) => {
      onDistanceTypeChange((type as unknown) as DistanceTypes);
    },
    [onDistanceTypeChange],
  );

  const onPositionChange = useCallback(
    (inputPlacement: EdgeType, value: number) => {
      if (creatorProduct.parentType === 'glass') {
        const { axisDistance, edgeDistance, data } = creatorProduct;

        const distance =
          distanceType === DistanceTypes.EDGE ? edgeDistance : axisDistance;

        let positionChange;

        switch (inputPlacement) {
          case 'top': {
            positionChange = {
              y: new Big(value).minus(new Big(distance.top)),
            };
            break;
          }
          case 'bottom': {
            positionChange = {
              y: new Big(distance.bottom).minus(new Big(value)),
            };
            break;
          }
          case 'left': {
            positionChange = {
              x: new Big(value).minus(new Big(distance.left)),
            };
            break;
          }
          case 'right': {
            positionChange = {
              x: new Big(distance.right).minus(new Big(value)),
            };
            break;
          }
        }

        updateProjectProduct(
          moveProduct(mountedEdgePoints, data, positionChange),
        );
      }
    },
    [creatorProduct, distanceType, mountedEdgePoints, updateProjectProduct],
  );

  const onTopChange = useCallback((value) => onPositionChange('top', value), [
    onPositionChange,
  ]);

  const onBottomChange = useCallback(
    (value) => onPositionChange('bottom', value),
    [onPositionChange],
  );

  const onLeftChange = useCallback((value) => onPositionChange('left', value), [
    onPositionChange,
  ]);

  const onRightChange = useCallback(
    (value) => onPositionChange('right', value),
    [onPositionChange],
  );

  const disabled = useMemo(() => {
    const { anchor } = creatorProduct;
    const corners =
      anchor === 'topLeft' ||
      anchor === 'bottomLeft' ||
      anchor === 'topRight' ||
      anchor === 'bottomRight';
    const disableVertical =
      anchor === 'top' ||
      anchor === 'bottom' ||
      corners ||
      !!multiselect ||
      isBar;
    const disableHorizontal =
      anchor === 'left' ||
      anchor === 'right' ||
      corners ||
      !!multiselect ||
      isBar;
    return {
      top: disableVertical,
      bottom: disableVertical,
      left: disableHorizontal,
      right: disableHorizontal,
    };
  }, [creatorProduct, isBar, multiselect]);

  const minLimit = useMemo(() => {
    let limits = {
      top: 0,
      bottom: 0,
      left: 0,
      right: 0,
    };

    if (
      creatorProduct &&
      creatorProduct.parentType === 'glass' &&
      minGapToEdge?.top !== undefined &&
      minGapToEdge?.bottom !== undefined &&
      minGapToEdge?.right !== undefined &&
      minGapToEdge?.left !== undefined
    ) {
      const { dimensionLinePadding } = creatorProduct.glassProperties;
      const dimensionLinePaddingTop =
        dimensionLinePadding.top - minGapToEdge.top;
      const dimensionLinePaddingBottom =
        dimensionLinePadding.bottom - minGapToEdge.bottom;
      const dimensionLinePaddingRight =
        dimensionLinePadding.right - minGapToEdge.right;
      const dimensionLinePaddingLeft =
        dimensionLinePadding.left - minGapToEdge.left;
      const horizontal =
        distanceType === DistanceTypes.AXIS
          ? new Big(creatorProduct.data.width).div(2)
          : new Big(0);
      const vertical =
        distanceType === DistanceTypes.AXIS
          ? new Big(creatorProduct.data.height).div(2)
          : new Big(0);
      limits = {
        top: fixValue(vertical.minus(dimensionLinePaddingTop)),
        bottom: fixValue(vertical.minus(dimensionLinePaddingBottom)),
        left: fixValue(horizontal.minus(dimensionLinePaddingLeft)),
        right: fixValue(horizontal.minus(dimensionLinePaddingRight)),
      };
    }

    return limits;
  }, [creatorProduct, distanceType]);

  const maxLimit = useMemo(() => {
    let limits = {
      top: 0,
      bottom: 0,
      left: 0,
      right: 0,
    };
    if (
      creatorProduct &&
      creatorProduct.parentType === 'glass' &&
      minGapToEdge?.top !== undefined &&
      minGapToEdge?.bottom !== undefined &&
      minGapToEdge?.right !== undefined &&
      minGapToEdge?.left !== undefined
    ) {
      const { dimensionLinePadding } = creatorProduct.glassProperties;
      const dimensionLinePaddingTop =
        dimensionLinePadding.top - minGapToEdge.top;
      const dimensionLinePaddingBottom =
        dimensionLinePadding.bottom - minGapToEdge.top;
      const dimensionLinePaddingRight =
        dimensionLinePadding.right - minGapToEdge.right;
      const dimensionLinePaddingLeft =
        dimensionLinePadding.left - minGapToEdge.left;

      const horizontal =
        distanceType === DistanceTypes.AXIS
          ? new Big(creatorProduct.parent.width).minus(
              new Big(creatorProduct.data.width).div(2),
            )
          : new Big(creatorProduct.parent.width).minus(
              creatorProduct.data.width,
            );
      const vertical =
        distanceType === DistanceTypes.AXIS
          ? new Big(creatorProduct.parent.height).minus(
              new Big(creatorProduct.data.height).div(2),
            )
          : new Big(creatorProduct.parent.height).minus(
              creatorProduct.data.height,
            );

      limits = {
        top: fixValue(vertical.plus(dimensionLinePaddingTop)),
        bottom: fixValue(vertical.plus(dimensionLinePaddingBottom)),
        left: fixValue(horizontal.plus(dimensionLinePaddingLeft)),
        right: fixValue(horizontal.plus(dimensionLinePaddingRight)),
      };
    }
    return limits;
  }, [creatorProduct, distanceType]);

  const distances = useMemo<Distance>(() => {
    if (!multiselect && creatorProduct.parentType === 'glass') {
      if (distanceType === DistanceTypes.EDGE || isBar) {
        return creatorProduct.edgeDistance;
      } else if (distanceType === DistanceTypes.AXIS) {
        return creatorProduct.axisDistance;
      }
    }
    return {
      top: 0,
      left: 0,
      bottom: 0,
      right: 0,
    };
  }, [creatorProduct, distanceType, isBar, multiselect]);

  return (
    <ProductPosition
      positioningType={(distanceType as unknown) as PositioningTypes}
      onTypeChange={changePositioningType}
      onBottomChange={onBottomChange}
      onLeftChange={onLeftChange}
      onRightChange={onRightChange}
      onTopChange={onTopChange}
      disabled={disabled}
      positions={distances}
      step={0.01}
      minLimit={minLimit}
      maxLimit={maxLimit}
      onlyEdge={!multiselect && isBar}
    />
  );
};

ProductPositioning.displayName = 'ProductPositioning';

export default ProductPositioning;
